export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/(notebook)/about": [6,[2]],
		"/console": [17],
		"/(notebook)/cpython/[...path]": [~7,[2]],
		"/(workspace)/gist": [8,[3]],
		"/(workspace)/gist/[gist_id]": [~9,[3]],
		"/(workspace)/github": [10,[3]],
		"/(workspace)/github/[owner]/[repo]": [~11,[3]],
		"/(workspace)/new": [12,[3]],
		"/oauth": [~18],
		"/(workspace)/playground": [13,[3]],
		"/pypi": [19,[4]],
		"/pypi/[project]": [~20,[4]],
		"/(workspace)/recents": [14,[3]],
		"/(workspace)/start": [15,[3]],
		"/(workspace)/telegraph/[path]": [~16,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';